import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [4,2];

export const dictionary = {
		"/(marketing-site)": [22,[4]],
		"/(admin)/account": [~9,[2]],
		"/(admin)/age-checks": [~10,[2]],
		"/(admin)/age-checks/new": [11,[2]],
		"/(marketing-site)/(static)/age-verification": [23,[4,5]],
		"/c/[slug]": [~28,[6]],
		"/(marketing-site)/(static)/faqs": [24,[4,5]],
		"/(auth)/login": [~15,[3]],
		"/(auth)/logout": [~16,[3]],
		"/(marketing-site)/(static)/pricing": [25,[4,5]],
		"/(marketing-site)/(static)/privacy": [26,[4,5]],
		"/(auth)/reset-password": [~17,[3]],
		"/(auth)/reset-password/sent": [19,[3]],
		"/(auth)/reset-password/[token]": [~18,[3]],
		"/(auth)/signup": [20,[3]],
		"/(marketing-site)/(static)/terms": [27,[4,5]],
		"/(admin)/transfers": [~12,[2]],
		"/(admin)/transfers/new": [14,[2]],
		"/(admin)/transfers/[slug]": [~13,[2]],
		"/t/[slug]": [~29,[7],[8]],
		"/t/[slug]/verify-age": [~30,[7],[8]],
		"/(auth)/verify": [~21,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';